import * as React from "react";
import "../fonts/stylesheet.css";

import MentorCard from "../components/MentorCard/MentorCard";
import Footer from "../sections/Footer/Footer";
import { Helmet } from "react-helmet";
import Navbar from "../sections/Navbar/Navbar";
import NetworkHero from "../sections/NetworkHero/NetworkHero";
import RegBanner from "../sections/RegBanner/RegBanner";

import {
  teamSection,
  teamHeading,
  greyText,
  conqCEOs,
  teamFilter,
  fButtonActive,
  fButtonInactive,
  fButton,
  searchBarContainer,
  searchBar,
  mentorGrid,
} from "../sections/Team/Team.module.css";

import { sponsType, line } from "../components/Sponsor/Sponsor.module.css";

const text = {
  fontFamily: "Manrope",
};

const AngelsJuryPage = () => {
  const [filterState, setFilterState] = React.useState("Academy");

  const [angels, setAngels] = React.useState();
  const [ref, setRef] = React.useState();

  const getRef = () => {
    fetch("https://conquest.prismic.io/api/v2/", {
      method: "GET",
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            getData(res.data.refs[0].ref);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };
  const getData = (key) => {
    fetch(
      "https://conquest.prismic.io/api/v2/documents/search?ref=" +
        key +
        "&access_token=MTY0NTI2NjYzMDcxOC5ZZ3ZQaVJFQUFDSUFSR05x.77-977-977-977-977-977-9CA9f77-9KO-_vULvv73vv70dEmvvv70J77-9LQok77-977-977-977-977-977-977-977-9&state=m4RTEd4rrZ",
      {
        method: "GET",
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            setAngels(res.data.results);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };

  React.useEffect(() => {
    getRef();
  }, []);

  return (
    <main style={text}>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Conquest: India's First and Largest Student-run Startup Accelerator
        </title>
      </Helmet>
      <Navbar />
      <NetworkHero
        img1="https://images.prismic.io/conquest/74f5f0b1-94b4-48bd-8818-187598b3e1a8_harshshah.jfif?auto=compress,format"
        img2="https://images.prismic.io/conquest/15423682-ab5d-42b5-9c77-533ecc079957_Dinesh+Agarwal.jpg?auto=compress,format"
        img3="https://images.prismic.io/conquest/c1f83e1c-e246-4985-8ec1-5c9bf65bcbd0_Abhinav+Pathak.jpg?auto=compress,format"
        img4="https://images.prismic.io/conquest/ea98aa63-8af6-45dc-b333-58663a8ce181_svickram.jfif?auto=compress,format"
        img5="https://images.prismic.io/conquest/57c1f0bd-5bc5-44ab-829a-64593c8fb1d1_vivekbihanijfif.jfif?auto=compress,format"
        img6="https://images.prismic.io/conquest/444f8b66-acf2-4ef0-8811-ed03cf6abe73_Mithun+Sundar.jpg?auto=compress,format"
        meet="meet our"
        context="jury"
        desc="Our Jury has witnessed some great industry names and has provided our startups with in-depth insights about their businesses."
      />
      {/* <div class={searchBarContainer}>
                <input type="text" class={searchBar} placeholder="Search for mentors by name, interests, or company" />
            </div>

            <div class={teamFilter}>
                <button 
                    class={filterState === "All" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("All")}
                >All</button>
                <button 
                    class={filterState === "Academy" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Academy")}
                >Electric Vehicles</button>
                <button 
                    class={filterState === "Design" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Design")}
                >Crypto</button>
                <button 
                    class={filterState === "Finance" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Finance")}
                >FinTech</button>
                <button 
                    class={filterState === "Media" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Media")}
                >SaaS</button>
                <button 
                    class={filterState === "Mentor" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Mentor")}
                >Cloud Computing</button>
                <button 
                    class={filterState === "Outreach" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Outreach")}
                >Growth</button>
                <button 
                    class={filterState === "Tech" ?
                    fButtonActive : fButtonInactive}
                    onClick={() => setFilterState("Tech")}
                >Hardware</button>
            </div> */}
      {/* <div>{JSON.stringify(angels)}</div> */}
      {/* <h2 className={sponsType}>ANGEL INVESTORS</h2> */}
      {/* {angels ? (
        <>
          {angels.map((result, id) => (
            <>
              <div class={mentorGrid}>
                {result.type === "horizontal_profile" &&
                result.data.type[0].text === "Angel" ? (
                  <>
                    {result.data.body ? (
                      <>
                        {result.data.body.map((ang, id) => (
                          <MentorCard
                            name={ang.primary.name[0].text}
                            imgSrc={ang.primary.image.url}
                            designation={ang.primary.designation[0].text}
                            tag1={ang.items[0].tag}
                            // tag2={ang.items[1].tag}
                            // tag3="WEB 3.0"
                          />
                        ))}
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </>
          ))}
        </>
      ) : null} */}
      <h2 className={sponsType}>JURY</h2>
      {angels ? (
        <>
          {angels.map((result, id) => (
            <>
              <div class={mentorGrid}>
                {result.type === "horizontal_profile" &&
                result.data.type[0].text === "Jury" ? (
                  <>
                    {result.data.body ? (
                      <>
                        {result.data.body.map((ang, id) => (
                          <MentorCard
                            name={ang.primary.name[0].text}
                            imgSrc={ang.primary.image.url}
                            designation={ang.primary.designation[0].text}
                            tag1={ang.items[0].tag}
                          />
                        ))}
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </>
          ))}
        </>
      ) : null}
      <RegBanner />
      <Footer />
    </main>
  );
};

export default AngelsJuryPage;
